import { HMI } from 'CORE__UI/apps/HamburgerApp/core__HamburgerMenu';
import { BREAKPOINT_L } from 'CORE__UI/globals/core__breakpoints';
import styled from 'styled-components';
const HMI_QR = styled(HMI)`
  font-size: 26px;
  @media (max-width: ${BREAKPOINT_L}) {
    display: block;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
`;
export { HMI_QR as HMI };
